import React, { ChangeEvent, MouseEvent, FormEvent, FC } from "react";

import { convertToDropdownOption } from "~/utils";

import { FormQuestionAnswerType, useBoolean } from "~/hooks/";

import { Form } from "~/components/atoms";
import {
  LabelWithTextField,
  LabelWithTextArea,
  EditAndFormSubmitButton,
  FolderIconWithTitle,
  LabelWithMultipleDropdownField,
} from "~/components/molecules";

import { MultiValueType, OptionType } from "~/domains";

type PropsType = {
  isSubmitting: boolean;
  defaultAccordionOpen: boolean;
  questionAnswer: FormQuestionAnswerType;
  selectableQuestionAnswerTagOptions: OptionType[];
  onChangeQuestionContent: (newValue: string) => void;
  onChangeAnswerContent: (newValue: string) => void;
  handleTagNameCreate: (newValue: string) => void;
  onChangeTags: (newValue: MultiValueType<OptionType>) => void;
  setNotReadOnly: () => void;
  setReadOnly: () => void;
  remove: () => void;
  save: () => void;
  onChangeLink: (newValue: string) => void;
};

export const QuestionAnswerForm: FC<PropsType> = ({
  isSubmitting,
  defaultAccordionOpen,
  questionAnswer,
  selectableQuestionAnswerTagOptions,
  remove,
  save,
  setNotReadOnly,
  setReadOnly,
  onChangeQuestionContent,
  onChangeAnswerContent,
  handleTagNameCreate,
  onChangeTags,
  onChangeLink,
}: PropsType) => {
  const accordionOpen = useBoolean(defaultAccordionOpen);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    save();
  };

  const dropdownTagValues = questionAnswer.questionAnswerTags.map((tag) =>
    convertToDropdownOption(tag),
  );

  const handleRemove = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const isConfirm = confirm("本当に削除しますか？");
    if (!isConfirm) return;

    remove();
  };

  const handleNotReadonly = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setNotReadOnly();
  };

  const handleReadonly = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setReadOnly();
  };

  return (
    <Form className="space-y-4" onSubmit={onSubmit}>
      <FolderIconWithTitle
        title={questionAnswer.questionContent}
        onClick={accordionOpen.toggle}
      />
      {accordionOpen.isChecked && (
        <div className="space-y-6 ml-9">
          <LabelWithTextField
            labelText="Q (質問)"
            type="text"
            name="question"
            placeholder="Q (質問)"
            required
            disabled={questionAnswer.isReadOnly}
            value={questionAnswer.questionContent}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onChangeQuestionContent(e.target.value)
            }
          />
          <LabelWithTextArea
            labelText="A (答え)"
            name="answer"
            placeholder="A (答え)"
            required
            disabled={questionAnswer.isReadOnly}
            rows={5}
            value={questionAnswer.answerContent}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              onChangeAnswerContent(e.target.value)
            }
          />
          <LabelWithTextField
            labelText="参考リンク"
            type="url"
            name="link"
            placeholder="参考リンク"
            disabled={questionAnswer.isReadOnly}
            value={questionAnswer.link}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onChangeLink(e.target.value)
            }
          />
          <LabelWithMultipleDropdownField
            labelText="タグ"
            name="questionAnswerTag"
            options={selectableQuestionAnswerTagOptions}
            value={dropdownTagValues}
            onChange={onChangeTags}
            onCreateOption={handleTagNameCreate}
            creatable
            isDisabled={questionAnswer.isReadOnly}
          />
          <EditAndFormSubmitButton
            isSubmitting={isSubmitting}
            isReadOnly={questionAnswer.isReadOnly}
            handleNotReadonly={handleNotReadonly}
            handleReadonly={handleReadonly}
            handleRemove={handleRemove}
          />
        </div>
      )}
    </Form>
  );
};
