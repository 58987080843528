import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApisAdminOperatorsCompaniesCopiesIndexResponseType } from "~/domains";

type ParamsType = {
  companyId: string;
};

const request = async ({
  companyId,
}: ParamsType): Promise<ApisAdminOperatorsCompaniesCopiesIndexResponseType> => {
  const response =
    await new ApiClient().get<ApisAdminOperatorsCompaniesCopiesIndexResponseType>(
      `/api/admin_operators/companies/${companyId}/copies`,
    );
  return response.data;
};

type QueryFnType = typeof request;

type PropsType = {
  companyId: string;
  config?: QueryConfigType<QueryFnType>;
};

export const useApisAdminOperatorsCompaniesCopiesIndex = ({
  companyId,
  config = {},
}: PropsType): UseQueryResult<ApisAdminOperatorsCompaniesCopiesIndexResponseType> => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["ApisAdminOperatorsCompaniesCopiesIndex", companyId],
    queryFn: () => request({ companyId }),
  });
};
