import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ResponseMessageType } from "~/domains";

type RequestDataType = {
  body: {
    email: string;
    lastName: string;
    firstName: string;
    katakanaLastName: string;
    katakanaFirstName: string;
    jobTitle: string;
    employeeRoleJa: string;
    jobCategoryJa: string;
    employeeCode: string;
    password: string;
    businessDivisions: { ja: string[] };
    businessSections: { ja: string[] };
  }[];
};

const request = ({ body }: RequestDataType) => {
  return new ApiClient()
    .post<ResponseMessageType>("/api/managers/bulk_invites", {
      invites: body,
    })
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisManagersBulkInvitesCreate = ({
  options = {},
}: PropsType = {}): UseMutationResult<ResponseMessageType, RequestDataType> => {
  return useMutation(request, { ...options });
};
