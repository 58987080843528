type QParams = Record<string, string | undefined>;

class BaseRoute {
  protected static path(basePath: string, q?: QParams): string {
    if (!q) return basePath;
    const searchParams = new URLSearchParams(
      Object.entries(q).map(([key, value]) => [key, value ?? ""]), // undefinedを空文字に変換
    );
    return `${basePath}?${searchParams.toString()}`;
  }
}

// ログイン不要なページ
export class MyRoutes extends BaseRoute {
  static signIn({ q }: { q?: QParams } = {}): string {
    return this.path("/sign_in", q);
  }

  static signUp({ id, q }: { id: string; q?: QParams }): string {
    return this.path(`/sign_up/${id}`, q);
  }

  static passwordReset({ q }: { q?: QParams } = {}): string {
    return this.path("/password/reset", q);
  }

  static passwordEdit({ q }: { q?: QParams } = {}): string {
    return this.path("/password/edit", q);
  }

  static partnerConsolesSignIn({ q }: { q?: QParams } = {}): string {
    return this.path("/partner_consoles/sign_in", q);
  }

  static browserInfo({ q }: { q?: QParams } = {}): string {
    return this.path("/browser_info", q);
  }

  static Members = class Members extends BaseRoute {
    static profileShow({ q }: { q?: QParams } = {}): string {
      return this.path("/profile", q);
    }

    static settingProfileShow({ q }: { q?: QParams } = {}): string {
      return this.path("/setting/profile", q);
    }

    static settingPasswordShow({ q }: { q?: QParams } = {}): string {
      return this.path("/setting/password", q);
    }

    static settingEmailShow({ q }: { q?: QParams } = {}): string {
      return this.path("/setting/email", q);
    }

    static settingEmailEdit({ id, q }: { id: string; q?: QParams }): string {
      return this.path(`/setting/email/${id}/edit`, q);
    }

    static insightPostsIndex({ q }: { q?: QParams } = {}): string {
      return this.path("/insight_posts", q);
    }

    static root({ q }: { q?: QParams } = {}): string {
      return this.path("/", q);
    }

    static insightPostsShow({ id, q }: { id: string; q?: QParams }): string {
      return this.path(`/insight_posts/${id}`, q);
    }

    static insightPostCommentsShow({
      id,
      q,
    }: {
      id: string;
      q?: QParams;
    }): string {
      return this.path(`/insight_post_comments/${id}`, q);
    }

    static knowledgeInsightPostCommentsShow({
      id,
      q,
    }: {
      id: string;
      q?: QParams;
    }): string {
      return this.path(`/knowledge_insight_post_comments/${id}`, q);
    }

    static bookmarkedInsightPostsIndex({ q }: { q?: QParams } = {}): string {
      return this.path("/bookmark/insight_posts", q);
    }

    static bookmarkInsightPostBookmarkFoldersInsightPostsIndex({
      id,
      q,
    }: {
      id: string;
      q?: QParams;
    }): string {
      return this.path(`/bookmark/insight_post_bookmark_folders/${id}`, q);
    }

    static knowledgeInsightPostsShow({
      id,
      q,
    }: {
      id: string;
      q?: QParams;
    }): string {
      return this.path(`/knowledge_insight_posts/${id}`, q);
    }

    static bookmarkedKnowledgeInsightPostsIndex({
      q,
    }: {
      q?: QParams;
    } = {}): string {
      return this.path("/bookmark/knowledge_insight_posts", q);
    }

    static bookmarkKnowledgeInsightPostBookmarkFoldersKnowledgeInsightPostsIndex({
      id,
      q,
    }: {
      id: string;
      q?: QParams;
    }): string {
      return this.path(
        `/bookmark/knowledge_insight_post_bookmark_folders/${id}`,
        q,
      );
    }

    static knowledgeInsightPostsFromTeamsIndex({
      q,
    }: {
      q?: QParams;
    } = {}): string {
      return this.path("/knowledge_post/team", q);
    }

    static knowledgeInsightPostsFromManagersIndex({
      employeeRole,
      q,
    }: {
      employeeRole: string;
      q?: QParams;
    }): string {
      return this.path(`/knowledge_post/${employeeRole}`, q);
    }

    static focusChallengesIndex({ q }: { q?: QParams } = {}): string {
      return this.path("/focus_challenges", q);
    }

    static focusChallengesShow({ id, q }: { id: string; q?: QParams }): string {
      return this.path(`/focus_challenges/${id}`, q);
    }

    static focusChallengeEmployeeRepliesShow({
      id,
      q,
    }: {
      id: string;
      q?: QParams;
    }): string {
      return this.path(`/focus_challenge_employee_replies/${id}`, q);
    }

    static focusChallengeEmployeeEffortsShow({
      id,
      q,
    }: {
      id: string;
      q?: QParams;
    }): string {
      return this.path(`/focus_challenge_employee_efforts/${id}`, q);
    }

    static kaizenIdeasIndex({ q }: { q?: QParams } = {}): string {
      return this.path("/kaizen_ideas", q);
    }

    static kaizenIdeasShow({ id, q }: { id: string; q?: QParams }): string {
      return this.path(`/kaizen_ideas/${id}`, q);
    }

    static kaizenIdeaRepliesShow({
      id,
      q,
    }: {
      id: string;
      q?: QParams;
    }): string {
      return this.path(`/kaizen_idea_replies/${id}`, q);
    }

    static documentFileCategoriesIndex({
      employeeRole,
      q,
    }: {
      employeeRole: string;
      q?: QParams;
    }): string {
      return this.path(`/document_file_category/${employeeRole}`, q);
    }

    static salesConceptCategoriesIndex({
      employeeRole,
      q,
    }: {
      employeeRole: string;
      q?: QParams;
    }): string {
      return this.path(`/sales_concept_category/${employeeRole}`, q);
    }

    static salesConceptsShow({ id, q }: { id: string; q?: QParams }): string {
      return this.path(`/sales_concepts/${id}`, q);
    }

    static questionAnswerCategoriesIndex({
      employeeRole,
      q,
    }: {
      employeeRole: string;
      q?: QParams;
    }): string {
      return this.path(`/question_answer_category/${employeeRole}`, q);
    }

    static privateQuestionAnswerCategoriesIndex({
      q,
    }: {
      q?: QParams;
    } = {}): string {
      return this.path("/question_answer_category/member", q);
    }

    static kpiDailyPerformancesShow({
      date,
      q,
    }: {
      date: string;
      q?: QParams;
    }): string {
      return this.path(`/kpi_daily_performances/${date}`, q);
    }

    static kpiTermsIndex({ q }: { q?: QParams } = {}): string {
      return this.path("/kpi_terms", q);
    }

    static termEndKpiTermsDashboardShow({
      id,
      q,
    }: {
      id: string;
      q?: QParams;
    }): string {
      return this.path(`/term_end_kpi_terms/${id}/dashboard`, q);
    }

    static kpiBusinessSectionsDashboardShow({
      q,
    }: {
      q?: QParams;
    } = {}): string {
      return this.path("/kpi_business_section_dashboard", q);
    }

    static meetingNotesNew({ q }: { q?: QParams } = {}): string {
      return this.path("/meeting_notes/new", q);
    }

    static meetingNotesIndex({ q }: { q?: QParams } = {}): string {
      return this.path("/meeting_notes", q);
    }

    static meetingNotesEdit({ id, q }: { id: string; q?: QParams }): string {
      return this.path(`/meeting_notes/${id}/edit`, q);
    }

    static skillEvaluationStandardSummaryCategoriesIndex({
      q,
    }: {
      q?: QParams;
    } = {}): string {
      return this.path("/skill_evaluation_standard_summary_categories", q);
    }

    static skillEvaluationStandardSummaryCategoriesShow({
      id,
      q,
    }: {
      id: string;
      q?: QParams;
    }): string {
      return this.path(
        `/skill_evaluation_standard_summary_categories/${id}`,
        q,
      );
    }

    static skillEvaluationTermsIndex({ q }: { q?: QParams } = {}): string {
      return this.path("/skill_evaluation_terms", q);
    }

    static skillEvaluationTermsShow({
      id,
      q,
    }: {
      id: string;
      q?: QParams;
    }): string {
      return this.path(`/skill_evaluation_terms/${id}`, q);
    }

    static skillEvaluationTermsResultShow({
      id,
      q,
    }: {
      id: string;
      q?: QParams;
    }): string {
      return this.path(`/skill_evaluation_terms/${id}/result`, q);
    }

    static skillEvaluationTermsResultIndex({
      q,
    }: { q?: QParams } = {}): string {
      return this.path("/skill_evaluation_term/results", q);
    }

    static roleplayingConditionsIndex({ q }: { q?: QParams } = {}): string {
      return this.path("/roleplaying_conditions", q);
    }

    static roleplayingConditionsNew({ q }: { q?: QParams } = {}): string {
      return this.path("/roleplaying_conditions/new", q);
    }

    static roleplayingConditionsEdit({
      id,
      q,
    }: {
      id: string;
      q?: QParams;
    }): string {
      return this.path(`/roleplaying_conditions/${id}/edit`, q);
    }

    static roleplayingConditionDashboardShow({
      q,
    }: {
      q?: QParams;
    } = {}): string {
      return this.path("/roleplaying_condition/dashboard", q);
    }

    static inputEmployeeSurveysIndex({ q }: { q?: QParams } = {}): string {
      return this.path("/input_employee_surveys", q);
    }

    static inputEmployeeSurveysShow({
      id,
      q,
    }: {
      id: string;
      q?: QParams;
    }): string {
      return this.path(`/input_employee_surveys/${id}`, q);
    }

    static employeeSurveySubmissionsIndex({ q }: { q?: QParams } = {}): string {
      return this.path("/employee_survey_submissions", q);
    }

    static employeeSurveySubmissionsShow({
      id,
      q,
    }: {
      id: string;
      q?: QParams;
    }): string {
      return this.path(`/employee_survey_submissions/${id}`, q);
    }

    static employeeSurveySubmissionRepliesShow({
      id,
      q,
    }: {
      id: string;
      q?: QParams;
    }): string {
      return this.path(`/employee_survey_submission_replies/${id}`, q);
    }

    static privatePersonalMemosIndex({ q }: { q?: QParams } = {}): string {
      return this.path("/private_personal_memos", q);
    }

    static employeesDirectMessageRoomsShow({
      employeeId,
      q,
    }: {
      employeeId: string;
      q?: QParams;
    }): string {
      return this.path(`/employees/${employeeId}/direct_message_rooms`, q);
    }

    static notFoundIndex({ q }: { q?: QParams } = {}): string {
      return this.path("*", q);
    }
  };

  static Managers = class Managers extends BaseRoute {
    private static readonly BASE_PATH = "/managers";

    static root({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}`, q);
    }

    static employeesNew({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/employees/new`, q);
    }

    static employeesIndex({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/employees`, q);
    }

    static employeesEdit({ id, q }: { id: string; q?: QParams }): string {
      return this.path(`${this.BASE_PATH}/employees/${id}/edit`, q);
    }

    static businessSectionReviewsIndex({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/business_section_reviews`, q);
    }

    static employeesEmployeeMemoReviewsIndex({
      employeeId,
      q,
    }: {
      employeeId: string;
      q?: QParams;
    }): string {
      return this.path(
        `${this.BASE_PATH}/employees/${employeeId}/employee_memo_reviews`,
        q,
      );
    }

    static employeesEmployeeCompetencyReviewsIndex({
      employeeId,
      q,
    }: {
      employeeId: string;
      q?: QParams;
    }): string {
      return this.path(
        `${this.BASE_PATH}/employees/${employeeId}/employee_competency_reviews`,
        q,
      );
    }

    static leftEmployeesIndex({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/left_employees`, q);
    }

    static leftEmployeesEdit({ id, q }: { id: string; q?: QParams }): string {
      return this.path(`${this.BASE_PATH}/left_employees/${id}/edit`, q);
    }

    static jobCategoriesNew({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/job_categories/new`, q);
    }

    static jobCategoriesIndex({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/job_categories`, q);
    }

    static jobCategoriesEdit({ id, q }: { id: string; q?: QParams }): string {
      return this.path(`${this.BASE_PATH}/job_categories/${id}/edit`, q);
    }

    static archivedJobCategoriesIndex({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/archived_job_categories`, q);
    }

    static archivedJobCategoryPhasesIndex({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/archived_job_category_phases`, q);
    }

    static providingServicesNew({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/providing_services/new`, q);
    }

    static providingServicesIndex({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/providing_services`, q);
    }

    static providingServicesEdit({
      id,
      q,
    }: {
      id: string;
      q?: QParams;
    }): string {
      return this.path(`${this.BASE_PATH}/providing_services/${id}/edit`, q);
    }

    static archivedProvidingServicesIndex({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/archived_providing_services`, q);
    }

    static businessDivisionsNew({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/business_divisions/new`, q);
    }

    static businessDivisionsIndex({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/business_divisions`, q);
    }

    static businessDivisionsEdit({
      id,
      q,
    }: {
      id: string;
      q?: QParams;
    }): string {
      return this.path(`${this.BASE_PATH}/business_divisions/${id}/edit`, q);
    }

    static archivedBusinessDivisionsIndex({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/archived_business_divisions`, q);
    }

    static businessSectionsNew({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/business_sections/new`, q);
    }

    static businessSectionsIndex({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/business_sections`, q);
    }

    static businessSectionsEdit({
      id,
      q,
    }: {
      id: string;
      q?: QParams;
    }): string {
      return this.path(`${this.BASE_PATH}/business_sections/${id}/edit`, q);
    }

    static businessSectionsCompetencyReviewsIndex({
      businessSectionId,
      q,
    }: {
      businessSectionId: string;
      q?: QParams;
    }): string {
      return this.path(
        `${this.BASE_PATH}/business_sections/${businessSectionId}/business_section_competency_reviews`,
        q,
      );
    }

    static businessSectionsMemoReviewsIndex({
      businessSectionId,
      q,
    }: {
      businessSectionId: string;
      q?: QParams;
    }): string {
      return this.path(
        `${this.BASE_PATH}/business_sections/${businessSectionId}/business_section_memo_reviews`,
        q,
      );
    }

    static archivedBusinessSectionsIndex({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/archived_business_sections`, q);
    }

    static feedbackInsightPostsIndex({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/feedback/insight_posts`, q);
    }

    static feedbackFocusChallengesIndex({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/feedback/focus_challenges`, q);
    }

    static feedbackKaizenIdeasIndex({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/feedback/kaizen_ideas`, q);
    }

    static knowledgePostTeamIndex({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/knowledge_post/team`, q);
    }

    static knowledgePostFromManagersIndex({
      employeeRole,
      q,
    }: {
      employeeRole: string;
      q?: QParams;
    }): string {
      return this.path(`${this.BASE_PATH}/knowledge_post/${employeeRole}`, q);
    }

    static focusChallengesIndex({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/focus_challenges`, q);
    }

    static focusChallengesNew({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/focus_challenges/new`, q);
    }

    static focusChallengesEdit({ id, q }: { id: string; q?: QParams }): string {
      return this.path(`${this.BASE_PATH}/focus_challenges/${id}/edit`, q);
    }

    static kpiTermsNew({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/kpi_terms/new`, q);
    }

    static kpiTermsEdit({ id, q }: { id: string; q?: QParams }): string {
      return this.path(`${this.BASE_PATH}/kpi_terms/${id}/edit`, q);
    }

    static kpiTermsIndex({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/kpi_terms`, q);
    }

    static kpiTermsDashboardShow({
      id,
      q,
    }: {
      id: string;
      q?: QParams;
    }): string {
      return this.path(`${this.BASE_PATH}/kpi_terms/${id}/dashboard`, q);
    }

    static belongingEmployeesKpiTermsIndex({
      q,
    }: {
      q?: QParams;
    } = {}): string {
      return this.path(`${this.BASE_PATH}/belonging_employees/kpi_terms`, q);
    }

    static documentFileCategoriesIndex({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/document_file_categories`, q);
    }

    static documentFileCategoriesNew({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/document_file_categories/new`, q);
    }

    static documentFileCategoriesEdit({
      id,
      q,
    }: {
      id: string;
      q?: QParams;
    }): string {
      return this.path(
        `${this.BASE_PATH}/document_file_categories/${id}/edit`,
        q,
      );
    }

    static documentFileCategoriesDisplayOrderIndex({
      q,
    }: {
      q?: QParams;
    } = {}): string {
      return this.path(
        `${this.BASE_PATH}/document_file_category/display_orders`,
        q,
      );
    }

    static questionAnswerCategoriesIndex({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/question_answer_categories`, q);
    }

    static questionAnswerCategoriesNew({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/question_answer_categories/new`, q);
    }

    static questionAnswerCategoriesEdit({
      id,
      q,
    }: {
      id: string;
      q?: QParams;
    }): string {
      return this.path(
        `${this.BASE_PATH}/question_answer_categories/${id}/edit`,
        q,
      );
    }

    static questionAnswerCategoriesDisplayOrderIndex({
      q,
    }: {
      q?: QParams;
    } = {}): string {
      return this.path(
        `${this.BASE_PATH}/question_answer_category/display_orders`,
        q,
      );
    }

    static privateQuestionAnswersIndex({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/private_question_answers`, q);
    }

    static belongingEmployeesPrivateQuestionAnswersIndex({
      q,
    }: {
      q?: QParams;
    } = {}): string {
      return this.path(
        `${this.BASE_PATH}/belonging_employees/private_question_answers`,
        q,
      );
    }

    static salesConceptCategoriesIndex({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/sales_concept_categories`, q);
    }

    static salesConceptCategoriesNew({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/sales_concept_categories/new`, q);
    }

    static salesConceptCategoriesEdit({
      id,
      q,
    }: {
      id: string;
      q?: QParams;
    }): string {
      return this.path(
        `${this.BASE_PATH}/sales_concept_categories/${id}/edit`,
        q,
      );
    }

    static salesConceptCategoriesDisplayOrderIndex({
      q,
    }: {
      q?: QParams;
    } = {}): string {
      return this.path(
        `${this.BASE_PATH}/sales_concept_category/display_orders`,
        q,
      );
    }

    static meetingNoteTemplatesNew({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/meeting_note_templates/new`, q);
    }

    static meetingNoteTemplatesEdit({
      id,
      q,
    }: {
      id: string;
      q?: QParams;
    }): string {
      return this.path(
        `${this.BASE_PATH}/meeting_note_templates/${id}/edit`,
        q,
      );
    }

    static meetingNoteTemplatesIndex({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/meeting_note_templates`, q);
    }

    static meetingNotesNew({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/meeting_notes/new`, q);
    }

    static meetingNotesEdit({ id, q }: { id: string; q?: QParams }): string {
      return this.path(`${this.BASE_PATH}/meeting_notes/${id}/edit`, q);
    }

    static meetingNotesIndex({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/meeting_notes`, q);
    }

    static belongingEmployeesMeetingNotesIndex({
      q,
    }: {
      q?: QParams;
    } = {}): string {
      return this.path(
        `${this.BASE_PATH}/belonging_employees/meeting_notes`,
        q,
      );
    }

    static insightFocusKaizenContentTemplatesIndex({
      q,
    }: {
      q?: QParams;
    } = {}): string {
      return this.path(
        `${this.BASE_PATH}/insight_focus_kaizen_content_templates`,
        q,
      );
    }

    static insightFocusKaizenContentTemplatesNew({
      q,
    }: {
      q?: QParams;
    } = {}): string {
      return this.path(
        `${this.BASE_PATH}/insight_focus_kaizen_content_templates/new`,
        q,
      );
    }

    static insightFocusKaizenContentTemplatesEdit({
      id,
      q,
    }: {
      id: string;
      q?: QParams;
    }): string {
      return this.path(
        `${this.BASE_PATH}/insight_focus_kaizen_content_templates/${id}/edit`,
        q,
      );
    }

    static roleplayingConditionTemplatesNew({
      q,
    }: {
      q?: QParams;
    } = {}): string {
      return this.path(
        `${this.BASE_PATH}/roleplaying_condition_templates/new`,
        q,
      );
    }

    static roleplayingConditionTemplatesEdit({
      id,
      q,
    }: {
      id: string;
      q?: QParams;
    }): string {
      return this.path(
        `${this.BASE_PATH}/roleplaying_condition_templates/${id}/edit`,
        q,
      );
    }

    static roleplayingConditionTemplatesIndex({
      q,
    }: {
      q?: QParams;
    } = {}): string {
      return this.path(`${this.BASE_PATH}/roleplaying_condition_templates`, q);
    }

    static roleplayingConditionEmployeesDashboardShow({
      q,
    }: {
      q?: QParams;
    } = {}): string {
      return this.path(
        `${this.BASE_PATH}/roleplaying_condition/employees/dashboard`,
        q,
      );
    }

    static roleplayingConditionsIndex({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/roleplaying_conditions`, q);
    }

    static roleplayingConditionsNew({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/roleplaying_conditions/new`, q);
    }

    static roleplayingConditionsEdit({
      id,
      q,
    }: {
      id: string;
      q?: QParams;
    }): string {
      return this.path(
        `${this.BASE_PATH}/roleplaying_conditions/${id}/edit`,
        q,
      );
    }

    static belongingEmployeesRoleplayingConditionsIndex({
      q,
    }: {
      q?: QParams;
    } = {}): string {
      return this.path(
        `${this.BASE_PATH}/belonging_employees/roleplaying_conditions`,
        q,
      );
    }

    static skillEvaluationStandardSummaryCategoriesNew({
      q,
    }: {
      q?: QParams;
    } = {}): string {
      return this.path(
        `${this.BASE_PATH}/skill_evaluation_standard_summary_categories/new`,
        q,
      );
    }

    static skillEvaluationStandardSummaryCategoriesEdit({
      id,
      q,
    }: {
      id: string;
      q?: QParams;
    }): string {
      return this.path(
        `${this.BASE_PATH}/skill_evaluation_standard_summary_categories/${id}/edit`,
        q,
      );
    }

    static skillEvaluationStandardSummaryCategoriesIndex({
      q,
    }: {
      q?: QParams;
    } = {}): string {
      return this.path(
        `${this.BASE_PATH}/skill_evaluation_standard_summary_categories`,
        q,
      );
    }

    static skillEvaluationStandardSummaryCategoriesDisplayOrderIndex({
      q,
    }: {
      q?: QParams;
    } = {}): string {
      return this.path(
        `${this.BASE_PATH}/skill_evaluation_standard_summary_category/display_orders`,
        q,
      );
    }

    static skillEvaluationTermsNew({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/skill_evaluation_terms/new`, q);
    }

    static skillEvaluationTermsEdit({
      id,
      q,
    }: {
      id: string;
      q?: QParams;
    }): string {
      return this.path(
        `${this.BASE_PATH}/skill_evaluation_terms/${id}/edit`,
        q,
      );
    }

    static skillEvaluationTermsIndex({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/skill_evaluation_terms`, q);
    }

    static skillEvaluationTermsShow({
      id,
      q,
    }: {
      id: string;
      q?: QParams;
    }): string {
      return this.path(`${this.BASE_PATH}/skill_evaluation_terms/${id}`, q);
    }

    static skillEvaluationTermsResultShow({
      id,
      q,
    }: {
      id: string;
      q?: QParams;
    }): string {
      return this.path(
        `${this.BASE_PATH}/skill_evaluation_terms/${id}/results`,
        q,
      );
    }

    static belongingEmployeesSkillEvaluationTermsIndex({
      q,
    }: {
      q?: QParams;
    } = {}): string {
      return this.path(
        `${this.BASE_PATH}/belonging_employees/skill_evaluation_terms`,
        q,
      );
    }

    static insightPostObjectivesIndex({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/insight_post_objectives`, q);
    }

    static insightPostObjectivesNew({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/insight_post_objectives/new`, q);
    }

    static insightPostObjectivesEdit({
      id,
      q,
    }: {
      id: string;
      q?: QParams;
    }): string {
      return this.path(
        `${this.BASE_PATH}/insight_post_objectives/${id}/edit`,
        q,
      );
    }

    static insightPostObjectivesEmployeeAndInsightPostObjectiveShow({
      insightPostObjectiveId,
      q,
    }: {
      insightPostObjectiveId: string;
      q?: QParams;
    }): string {
      return this.path(
        `${this.BASE_PATH}/insight_post_objectives/${insightPostObjectiveId}/employee_and_insight_post_objectives`,
        q,
      );
    }

    static belongingEmployeesInsightPostObjectivesIndex({
      q,
    }: {
      q?: QParams;
    } = {}): string {
      return this.path(
        `${this.BASE_PATH}/belonging_employees/insight_post_objectives`,
        q,
      );
    }

    static employeeSurveysIndex({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/employee_surveys`, q);
    }

    static employeeSurveysNew({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/employee_surveys/new`, q);
    }

    static employeeSurveysEdit({ id, q }: { id: string; q?: QParams }): string {
      return this.path(`${this.BASE_PATH}/employee_surveys/${id}/edit`, q);
    }

    static employeeSurveysSummarySubmissionsShow({
      id,
      q,
    }: {
      id: string;
      q?: QParams;
    }): string {
      return this.path(
        `${this.BASE_PATH}/employee_surveys/${id}/summary/submissions`,
        q,
      );
    }

    static employeeSurveySubmissionsIndex({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/employee_survey_submissions`, q);
    }

    static profilesShow({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/profile`, q);
    }
  };

  static AnalyticsPlans = class AnalyticsPlans extends BaseRoute {
    private static readonly BASE_PATH = "/analytics_plans";

    static root({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}`, q);
    }

    static cumulativeAnalysis({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/cumulative_analysis`, q);
    }

    static detailAnalysis({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/detail_analysis`, q);
    }

    static postsCalendars({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/posts_calendars`, q);
    }

    static feedbackAnalysis({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/feedback_analysis`, q);
    }

    static insightPostObjectives({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/insight_post_objectives`, q);
    }

    static knowledgeInsightPosts({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/knowledge_insight_posts`, q);
    }

    static kpiTerms({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/kpi_terms`, q);
    }

    static documentFileCategories({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/document_file_categories`, q);
    }

    static roleplayingConditions({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/roleplaying_conditions`, q);
    }

    static meetingNotes({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/meeting_notes`, q);
    }

    static questionAnswerCategories({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/question_answer_categories`, q);
    }

    static skillEvaluationTerms({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/skill_evaluation_terms`, q);
    }

    static skillEvaluationStandardSummaryCategories({
      q,
    }: { q?: QParams } = {}): string {
      return this.path(
        `${this.BASE_PATH}/skill_evaluation_standard_summary_categories`,
        q,
      );
    }

    static salesConceptCategories({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/sales_concept_categories`, q);
    }
  };

  static PartnerConsoles = class PartnerConsoles extends BaseRoute {
    private static readonly BASE_PATH = "/partner_consoles";

    static root({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}`, q);
    }

    static settingProfileShow({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/setting/profile`, q);
    }

    static settingPasswordShow({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/setting/password`, q);
    }

    static analyticsIndex({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/analytics`, q);
    }

    static analyticsCumulativeAnalysis({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/analytics/cumulative_analysis`, q);
    }

    static analyticsDetailAnalysis({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/analytics/detail_analysis`, q);
    }

    static analyticsDocumentFileCategories({
      q,
    }: { q?: QParams } = {}): string {
      return this.path(
        `${this.BASE_PATH}/analytics/document_file_categories`,
        q,
      );
    }

    static analyticsFeedbackAnalysis({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/analytics/feedback_analysis`, q);
    }

    static analyticsInsightPostObjectives({ q }: { q?: QParams } = {}): string {
      return this.path(
        `${this.BASE_PATH}/analytics/insight_post_objectives`,
        q,
      );
    }

    static analyticsKnowledgeInsightPosts({ q }: { q?: QParams } = {}): string {
      return this.path(
        `${this.BASE_PATH}/analytics/knowledge_insight_posts`,
        q,
      );
    }

    static analyticsKpiTerms({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/analytics/kpi_terms`, q);
    }

    static analyticsMeetingNotes({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/analytics/meeting_notes`, q);
    }

    static analyticsPostsCalendars({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/analytics/posts_calendars`, q);
    }

    static analyticsQuestionAnswerCategories({
      q,
    }: { q?: QParams } = {}): string {
      return this.path(
        `${this.BASE_PATH}/analytics/question_answer_categories`,
        q,
      );
    }

    static analyticsRoleplayingConditions({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/analytics/roleplaying_conditions`, q);
    }

    static analyticsSalesConceptCategories({
      q,
    }: { q?: QParams } = {}): string {
      return this.path(
        `${this.BASE_PATH}/analytics/sales_concept_categories`,
        q,
      );
    }

    static analyticsSkillEvaluationStandardSummaryCategories({
      q,
    }: { q?: QParams } = {}): string {
      return this.path(
        `${this.BASE_PATH}/analytics/skill_evaluation_standard_summary_categories`,
        q,
      );
    }

    static analyticsSkillEvaluationTerms({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/analytics/skill_evaluation_terms`, q);
    }
  };

  static AdminOperators = class AdminOperators extends BaseRoute {
    private static readonly BASE_PATH = "/admin_operators";

    static root({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}`, q);
    }

    static companiesIndex({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/companies`, q);
    }

    static companiesShow({ id, q }: { id: string; q?: QParams }): string {
      return this.path(`${this.BASE_PATH}/companies/${id}`, q);
    }

    static companiesNew({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/companies/new`, q);
    }

    static companiesEdit({ id, q }: { id: string; q?: QParams }): string {
      return this.path(`${this.BASE_PATH}/companies/${id}/edit`, q);
    }

    static companiesEmployeesIndex({
      companyId,
      q,
    }: {
      companyId: string;
      q?: QParams;
    }): string {
      return this.path(`${this.BASE_PATH}/companies/${companyId}/employees`, q);
    }

    static companiesBillingPeriodLogsIndex({
      companyId,
      q,
    }: {
      companyId: string;
      q?: QParams;
    }): string {
      return this.path(
        `${this.BASE_PATH}/companies/${companyId}/billing_period_logs`,
        q,
      );
    }

    static companiesAvailableEmployeeUpperLimitLogsIndex({
      companyId,
      q,
    }: {
      companyId: string;
      q?: QParams;
    }): string {
      return this.path(
        `${this.BASE_PATH}/companies/${companyId}/available_employee_upper_limit_logs`,
        q,
      );
    }

    static companiesContractPlanLogsIndex({
      companyId,
      q,
    }: {
      companyId: string;
      q?: QParams;
    }): string {
      return this.path(
        `${this.BASE_PATH}/companies/${companyId}/contract_plan_logs`,
        q,
      );
    }

    static companiesCopiesIndex({
      companyId,
      q,
    }: {
      companyId: string;
      q?: QParams;
    }): string {
      return this.path(`${this.BASE_PATH}/companies/${companyId}/copies`, q);
    }

    static versionsIndex({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/versions`, q);
    }

    static settingProfileShow({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/setting/profile`, q);
    }

    static settingPasswordShow({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/setting/password`, q);
    }

    static operatorsIndex({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/operators`, q);
    }

    static operatorsNew({ q }: { q?: QParams } = {}): string {
      return this.path(`${this.BASE_PATH}/operators/new`, q);
    }

    static operatorsEdit({ id, q }: { id: string; q?: QParams }): string {
      return this.path(`${this.BASE_PATH}/operators/${id}/edit`, q);
    }
  };
}
