import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ResponseMessageType } from "~/domains";

type RequestDataType = {
  id: string;
  body: {
    lastName: string;
    firstName: string;
    katakanaLastName: string;
    katakanaFirstName: string;
    jobTitle: string;
    employeeRoleId: number;
    jobCategoryId: string;
    employeeCode: string;
    businessDivisions: { ids: string[] };
    businessSections: { ids: string[] };
  };
};

const request = ({ id, body }: RequestDataType) => {
  return new ApiClient()
    .put<ResponseMessageType>(`/api/managers/employees/${id}`, {
      employee: body,
    })
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisManagersEmployeesUpdate = ({
  options = {},
}: PropsType = {}): UseMutationResult<ResponseMessageType, RequestDataType> => {
  return useMutation(request, { ...options });
};
