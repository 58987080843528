import React, { FC } from "react";

import { useApisAdminOperatorsVersionsIndex } from "~/hooks";

import { GridHeaderCells } from "~/components/atoms";
import { AdminOperatorLayout } from "~/components/layouts";
import { GridRow, InfiniteScrollWithMoreButton } from "~/components/molecules";

import { MyRoutes } from "~/config/Paths";

export const AdminOperatorsVersionsIndex: FC = () => {
  const breadCrumbItems = [
    {
      label: "運営者画面",
      href: MyRoutes.AdminOperators.root(),
    },
    {
      label: "バージョン一覧",
      href: MyRoutes.AdminOperators.versionsIndex(),
    },
  ];

  const { data, fetchNextPage, hasNextPage } =
    useApisAdminOperatorsVersionsIndex();

  return (
    <AdminOperatorLayout breadCrumbItems={breadCrumbItems}>
      <InfiniteScrollWithMoreButton
        itemsLength={data?.pages.length}
        nextFetchFunction={fetchNextPage}
        hasMore={hasNextPage}
      >
        <div className="mt-4 grid grid-cols-5">
          <GridHeaderCells
            texts={[
              "itemType",
              "Event",
              "createdAt",
              "companyName",
              "employeeName",
            ]}
          />
          {data?.pages.map((page) =>
            page.versions.map((version) => (
              <div
                key={version.id}
                className="col-span-5 grid grid-cols-5 hover:bg-secondary-300"
              >
                <GridRow
                  lists={[
                    version.itemType,
                    version.event,
                    version.createdAt,
                    version.whodunnit?.companyName,
                    version.whodunnit?.employeeName,
                  ]}
                />
              </div>
            )),
          )}
        </div>
      </InfiniteScrollWithMoreButton>
    </AdminOperatorLayout>
  );
};
