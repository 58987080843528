import React, { FC, MouseEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { toast } from "react-toastify";

import { GuestSignInRequest } from "~/utils";

import {
  useApisAdminOperatorsCompaniesContractActivesCreate,
  useApisAdminOperatorsCompaniesContractActivesUpdate,
  useApisAdminOperatorsCompaniesShow,
} from "~/hooks";

import { Button, GridHeaderCells, Link } from "~/components/atoms";
import { AdminOperatorLayout } from "~/components/layouts";
import { GridRow, InfiniteScrollWithMoreButton } from "~/components/molecules";

import { MyRoutes } from "~/config/Paths";

export const AdminOperatorsCompaniesShow: FC = () => {
  const navigate = useNavigate();
  const { id = "" } = useParams<{ id: string }>();

  const { data, fetchNextPage, hasNextPage, refetch } =
    useApisAdminOperatorsCompaniesShow({ id });

  const { mutate: activeRequest, isLoading: isActiveRequestLoading } =
    useApisAdminOperatorsCompaniesContractActivesCreate();
  const { mutate: inactiveRequest, isLoading: isInactiveRequestLoading } =
    useApisAdminOperatorsCompaniesContractActivesUpdate();
  const company = data?.pages[0]?.company;
  const breadCrumbItems = [
    {
      label: "運営者画面",
      href: MyRoutes.AdminOperators.root(),
    },
    {
      label: "会社一覧",
      href: MyRoutes.AdminOperators.companiesIndex(),
    },
    {
      label: company?.name || "",
      href: MyRoutes.AdminOperators.companiesShow({ id }),
    },
  ];

  const handleContract = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const isConfirm = confirm(
      "この操作は取り消せません。本当に契約をしますか？",
    );
    if (!isConfirm) return;

    activeRequest(
      { companyId: id },
      {
        onSuccess: async (data) => {
          toast(data.message);
          await refetch();
        },
      },
    );
  };

  const handleUnContract = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const isConfirm = confirm(
      "この操作は取り消せません。本当に契約を解除しますか？",
    );
    if (!isConfirm) return;

    inactiveRequest(
      { companyId: id },
      {
        onSuccess: (data) => {
          toast(data.message);
          navigate(MyRoutes.AdminOperators.root());
        },
      },
    );
  };

  const handleGuestLogin = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const isConfirm = confirm("ゲストログインをしますか？");
    if (!isConfirm) return;

    await GuestSignInRequest({ companyId: id });
  };

  return (
    <AdminOperatorLayout breadCrumbItems={breadCrumbItems}>
      {company && (
        <div className="mt-2">
          <div className="flex items-end space-x-1">
            <h1 className="font-semibold text-lg">{company.name}</h1>
            <span
              className={`${
                company.active
                  ? "text-primary-600 border-primary-600"
                  : "text-red-500 border-red-500"
              } border border-solid px-1 rounded text-sm`}
            >
              {company.active ? "契約中" : "未契約"}
            </span>
          </div>
          <p className="text-gray-500 text-sm">
            サブドメイン: {company.subdomain}
          </p>
          <div className="space-x-4">
            <Link
              to={MyRoutes.AdminOperators.companiesEdit({ id })}
              className="text-blue-400 underline visited:text-purple-600 text-sm"
            >
              会社情報の編集
            </Link>
            <Link
              to={MyRoutes.AdminOperators.companiesEmployeesIndex({
                companyId: id,
              })}
              className="text-blue-400 underline visited:text-purple-600 text-sm"
            >
              ユーザー一覧
            </Link>
            <Link
              to={MyRoutes.AdminOperators.companiesBillingPeriodLogsIndex({
                companyId: id,
              })}
              className="text-blue-400 underline visited:text-purple-600 text-sm"
            >
              請求期間ログ一覧
            </Link>
            <Link
              to={MyRoutes.AdminOperators.companiesAvailableEmployeeUpperLimitLogsIndex(
                { companyId: id },
              )}
              className="text-blue-400 underline visited:text-purple-600 text-sm"
            >
              ユーザー上限数ログ
            </Link>
            <Link
              to={MyRoutes.AdminOperators.companiesContractPlanLogsIndex({
                companyId: id,
              })}
              className="text-blue-400 underline visited:text-purple-600 text-sm"
            >
              契約プランログ
            </Link>
            <Link
              to={MyRoutes.AdminOperators.companiesCopiesIndex({
                companyId: id,
              })}
              className="text-blue-400 underline visited:text-purple-600 text-sm"
            >
              会社データのコピー
            </Link>
          </div>
        </div>
      )}
      <div className="mt-4 flex items-center justify-between">
        <h1 className="text-xl">契約ログ</h1>
        {company?.active ? (
          <div className="space-x-4">
            <Button
              text="ゲストログイン"
              color="primary"
              outline
              className="mt-4"
              onClick={handleGuestLogin}
            />
            <Button
              text="契約を終了する"
              color="red"
              outline
              onClick={handleUnContract}
              readonly={isInactiveRequestLoading}
            />
          </div>
        ) : (
          <Button
            text="契約を開始する"
            color="primary"
            onClick={handleContract}
            readonly={isActiveRequestLoading}
          />
        )}
      </div>
      <InfiniteScrollWithMoreButton
        itemsLength={data?.pages.length}
        nextFetchFunction={fetchNextPage}
        hasMore={hasNextPage}
      >
        <div className="mt-4 grid grid-cols-2">
          <GridHeaderCells texts={["開始日時", "終了日時"]} />
          {data?.pages.map((page) =>
            page.companyContractLogs.map((companyContractLog) => (
              <GridRow
                key={companyContractLog.id}
                lists={[
                  companyContractLog.startAt,
                  companyContractLog.endAt || "",
                ]}
              />
            )),
          )}
        </div>
      </InfiniteScrollWithMoreButton>
    </AdminOperatorLayout>
  );
};
