import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ApiManagersQuestionAnswerMutateType } from "~/domains";

type RequestDataType = {
  questionAnswerCategoryId: string;
  id: string;
  body: {
    questionContent: string;
    answerContent: string;
    link?: string;
    displayOrder: number;
    tagNames: string[];
  };
};

const request = ({ id, questionAnswerCategoryId, body }: RequestDataType) => {
  return new ApiClient()
    .put<ApiManagersQuestionAnswerMutateType>(
      `/api/managers/question_answer_categories/${questionAnswerCategoryId}/question_answers/${id}`,
      {
        questionAnswer: body,
      },
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisManagersQuestionAnswerCategoriesQuestionAnswersUpdate = ({
  options = {},
}: PropsType = {}): UseMutationResult<
  ApiManagersQuestionAnswerMutateType,
  RequestDataType
> => {
  return useMutation(request, { ...options });
};
