import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

import { FileRejection } from "react-dropzone/.";
import { toast } from "react-toastify";

import { parseCSV } from "~/utils";

import { useApisManagersBulkInvitesCreate } from "~/hooks";

import {
  Button,
  DownloadLink,
  DropzoneFileFieldWithFile,
} from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
} from "~/components/layouts";

export const ManagersEmployeeBulkInviteIndex: FC = () => {
  const navigate = useNavigate();

  const [parsedCSV, setParsedCSV] = useState<
    Record<string, string>[] | undefined
  >();
  const [tmpFile, setTmpFile] = useState<File | undefined>(undefined);
  const { mutate: postBulkInvites } = useApisManagersBulkInvitesCreate();

  const handleFileUpload = async (
    acceptedFile: File[],
    fileRejections: FileRejection[],
  ) => {
    if (fileRejections.length) {
      fileRejections.map(({ file, errors }) => {
        if (!errors[0]) return;
        toast.error(`${file.name} - ${errors[0].message}`);
      });
      return;
    }
    const file = acceptedFile[0];
    if (file) {
      setTmpFile(file);
      const fileText = await file.text();
      const csvData = parseCSV(fileText);
      setParsedCSV(csvData);
    }
  };

  const handleBulkUpload = () => {
    if (!parsedCSV) return;
    const isConfirmed = window.confirm("一括登録を実行しますか？");

    if (!isConfirmed) return;

    const body = parsedCSV.map((row) => ({
      email: row["メールアドレス"] || "",
      lastName: row["名前(姓)"] || "",
      firstName: row["名前(名)"] || "",
      katakanaLastName: row["カナ(姓)"] || "",
      katakanaFirstName: row["カナ(名)"] || "",
      jobTitle: row["職種"] || "",
      employeeRoleJa: row["権限"] || "",
      jobCategoryJa: row["職種"] || "",
      employeeCode: row["社員番号"] || "",
      password: row["初期パスワード"] || "",
      businessDivisions: {
        ja: (row["部署名"] || "").split(",").filter(Boolean),
      },
      businessSections: { ja: (row["課名"] || "").split(",").filter(Boolean) },
    }));

    postBulkInvites(
      { body: body },
      {
        onSuccess: (data) => {
          toast.success(data.message);
          navigate("/managers/employees");
        },
        onError: (error) => {
          toast.error(error.message);
          console.error(error);
        },
      },
    );
  };

  const breadCrumbItems = [
    { label: "ユーザー設定", href: "/managers/employees" },
    { label: "一括登録", href: "/managers/employees" },
  ];

  return (
    <EmployeeLayout withRightSidebar={false} breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar={false}>
        <div className="p-6 space-y-8">
          <div className="flex items-center justify-between">
            {/* ファイル操作ボタン */}
            <DownloadLink
              fileUrl="https://production-files.is-kagami.com/bulk_invite_sample.csv"
              fileName="bulk_invite_sample.csv"
            >
              <Button
                text={"サンプルのCSVをダウンロード"}
                color={"primary"}
                onClick={() => {}}
                outline
              />
            </DownloadLink>
            <Button
              text={"一括登録を実行する"}
              color={"primary"}
              onClick={handleBulkUpload}
              readonly={!tmpFile}
            />
          </div>
          <DropzoneFileFieldWithFile
            label="CSVをアップロード"
            acceptFileTypes={{
              "text/csv": [".csv"],
            }}
            fileClassName={
              "w-full cursor-pointer rounded-lg border-dashed border-2 border-gray-300 text-gray-500 flex justify-center items-center py-2 px-4"
            }
            fileName={tmpFile?.name}
            onDropFiles={handleFileUpload}
            noFilesText={"ファイルが選択されていません"}
          />
          {/* CSV内容の表示 */}
          {parsedCSV && parsedCSV.length > 0 && (
            <div className="overflow-x-auto border border-gray-300 rounded-lg p-4 bg-white shadow-md">
              <table className="min-w-full table-auto">
                <thead>
                  <tr>
                    {Object.keys(parsedCSV[0]!).map((key) => (
                      <th
                        key={key}
                        className="px-4 py-2 text-left text-sm font-medium text-gray-700 bg-gray-100 border-b"
                      >
                        {key}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {parsedCSV.map((row, index) => (
                    <tr
                      key={index}
                      className={`hover:bg-gray-50 ${
                        index % 2 === 0 ? "bg-gray-50" : "bg-white"
                      }`}
                    >
                      {Object.values(row).map((value, i) => (
                        <td
                          key={i}
                          className="px-4 py-2 text-sm text-gray-600 border-b"
                        >
                          {value}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </EmployeeLayoutMainContent>
    </EmployeeLayout>
  );
};
