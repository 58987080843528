import React, {
  FC,
  FormEvent,
  ChangeEvent,
  MouseEvent,
  useEffect,
} from "react";

import { useProvidersCurrentEmployee } from "~/hooks";

import { FormSubmitButton, Form, Button } from "~/components/atoms";
import {
  LabelWithTextField,
  LabelWithDropDownField,
  DisplayablePasswordFiled,
} from "~/components/molecules";
import { MultiParentsWithMultiChildrenDropdownField } from "~/components/organisms";

import {
  BusinessDivisionType,
  BusinessSectionType,
  ChildType,
  EmployeeRole,
  OptionType,
  ParentType,
  SingleValueType,
} from "~/domains";

type PropsType = {
  id?: string;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  isUpdating: boolean;
  isReadonly: boolean;
  selectableDivisions: BusinessDivisionType[];
  selectedDivisions: BusinessDivisionType[];
  onDivisionChange: (newValue: ParentType[]) => void;
  setSelectedDivisions: (newValue: BusinessDivisionType[]) => void;
  optionSelectableSections: ChildType[];
  optionSelectedSections: ChildType[];
  onSectionChange: (newValue: ChildType[]) => void;
  setSelectedSections: (newValue: BusinessSectionType[]) => void;
  jobCategoryOptions?: OptionType[];
  jobCategoryDropdownValue?: SingleValueType<OptionType>;
  jobCategoryDropdownOnChange: (newValue: SingleValueType<OptionType>) => void;
  jobTitleValue?: string;
  jobTitleOnChange: (e: ChangeEvent<HTMLInputElement>) => void;
  emailValue?: string;
  emailOnChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  lastNameValue?: string;
  lastNameOnChange: (e: ChangeEvent<HTMLInputElement>) => void;
  firstNameValue?: string;
  firstNameOnChange: (e: ChangeEvent<HTMLInputElement>) => void;
  katakanaLastNameValue?: string;
  katakanaLastNameOnChange: (e: ChangeEvent<HTMLInputElement>) => void;
  katakanaFirstNameValue?: string;
  katakanaFirstNameOnChange: (e: ChangeEvent<HTMLInputElement>) => void;
  employeeCodeValue?: string;
  employeeCodeOnChange: (e: ChangeEvent<HTMLInputElement>) => void;
  employeeRoleDropdownValue?: SingleValueType<OptionType>;
  employeeRoleDropdownOnChange: (newValue: SingleValueType<OptionType>) => void;
  buttonName: string;
  password?: {
    passwordValue: string;
    passwordOnChange: (e: ChangeEvent<HTMLInputElement>) => void;
  };
  unauthorized?: {
    isInviting: boolean;
    handleReInvite: (e: MouseEvent<HTMLButtonElement>) => void;
  };
};

export const EmployeeForm: FC<PropsType> = ({
  id = "",
  handleSubmit,
  isUpdating,
  isReadonly,
  selectableDivisions,
  selectedDivisions,
  onDivisionChange,
  optionSelectableSections,
  optionSelectedSections,
  onSectionChange,
  setSelectedDivisions,
  setSelectedSections,
  jobCategoryOptions,
  jobCategoryDropdownValue,
  jobCategoryDropdownOnChange,
  jobTitleValue,
  jobTitleOnChange,
  emailValue,
  emailOnChange,
  lastNameValue,
  lastNameOnChange,
  firstNameValue,
  firstNameOnChange,
  katakanaLastNameValue,
  katakanaLastNameOnChange,
  katakanaFirstNameValue,
  katakanaFirstNameOnChange,
  employeeRoleDropdownValue,
  employeeRoleDropdownOnChange,
  employeeCodeValue,
  employeeCodeOnChange,
  unauthorized,
  password,
  buttonName,
}: PropsType) => {
  const { currentEmployee } = useProvidersCurrentEmployee();
  const EmployeeRoleOptions =
    currentEmployee?.employeeRole.type === EmployeeRole.OWNER.type
      ? [
          {
            label: EmployeeRole.MEMBER.name,
            value: EmployeeRole.MEMBER.id.toString(),
          },
          {
            label: EmployeeRole.MANAGER.name,
            value: EmployeeRole.MANAGER.id.toString(),
          },
          {
            label: EmployeeRole.OWNER.name,
            value: EmployeeRole.OWNER.id.toString(),
          },
        ]
      : [
          {
            label: EmployeeRole.MEMBER.name,
            value: EmployeeRole.MEMBER.id.toString(),
          },
        ];

  const isCurrentRoleOwner =
    Number(employeeRoleDropdownValue?.value) === EmployeeRole.OWNER.id;

  useEffect(() => {
    if (!isCurrentRoleOwner || !currentEmployee) return;

    setSelectedDivisions(currentEmployee.businessDivisions);
    setSelectedSections(currentEmployee.businessSections);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCurrentRoleOwner, setSelectedDivisions, setSelectedSections]);

  return (
    <>
      <Form onSubmit={handleSubmit} className="space-y-6">
        {id && (
          <LabelWithTextField
            labelText="ID"
            disabled
            type="text"
            name="id"
            value={id}
          />
        )}
        <MultiParentsWithMultiChildrenDropdownField
          allParents={selectableDivisions}
          parentsValue={selectedDivisions}
          parentLabel="部署"
          parentsOnChange={onDivisionChange}
          parentRequired
          allChildren={optionSelectableSections}
          childrenValue={optionSelectedSections}
          childLabel="課"
          childrenOnChange={onSectionChange}
          childRequired
          isDisabled={isReadonly || isCurrentRoleOwner}
        />
        <LabelWithDropDownField
          labelText="職種"
          required
          name="jobCategoryId"
          isDisabled={isReadonly}
          options={jobCategoryOptions}
          value={jobCategoryDropdownValue}
          onChange={jobCategoryDropdownOnChange}
        />
        <LabelWithTextField
          labelText="役職"
          disabled={isReadonly}
          type="text"
          name="jobTitle"
          value={jobTitleValue}
          onChange={jobTitleOnChange}
        />
        <LabelWithTextField
          labelText="メールアドレス"
          type="email"
          name="email"
          autoComplete="email"
          disabled={!emailOnChange}
          value={emailValue}
          onChange={emailOnChange}
        />
        {password && (
          <DisplayablePasswordFiled
            label="初期パスワード"
            name="password"
            required
            value={password.passwordValue}
            onChange={password.passwordOnChange}
          />
        )}
        <LabelWithTextField
          labelText="社員番号"
          disabled={isReadonly}
          type="text"
          name="employeeCode"
          required
          value={employeeCodeValue}
          onChange={employeeCodeOnChange}
        />
        <div className="grid grid-cols-2 gap-x-8">
          <LabelWithTextField
            labelText="名前(姓)"
            type="text"
            name="lastName"
            autoComplete="family-name"
            disabled={isReadonly}
            required
            value={lastNameValue}
            onChange={lastNameOnChange}
          />
          <LabelWithTextField
            labelText="名前(名)"
            type="text"
            name="firstName"
            autoComplete="given-name"
            disabled={isReadonly}
            required
            value={firstNameValue}
            onChange={firstNameOnChange}
          />
        </div>
        <div className="grid grid-cols-2 gap-x-8">
          <LabelWithTextField
            labelText="カナ(姓)"
            type="text"
            name="katakanaLastName"
            autoComplete="family-name"
            disabled={isReadonly}
            value={katakanaLastNameValue}
            onChange={katakanaLastNameOnChange}
          />
          <LabelWithTextField
            labelText="カナ(名)"
            type="text"
            name="katakanaFirstName"
            autoComplete="given-name"
            disabled={isReadonly}
            value={katakanaFirstNameValue}
            onChange={katakanaFirstNameOnChange}
          />
        </div>
        <LabelWithDropDownField
          labelText="権限"
          required
          name="employeeRoleId"
          isDisabled={isReadonly}
          options={EmployeeRoleOptions}
          value={employeeRoleDropdownValue}
          onChange={employeeRoleDropdownOnChange}
        />
        <div className="flex items-center space-x-4">
          {unauthorized && (
            <Button
              text="再度招待する"
              color="primary"
              outline
              readonly={unauthorized.isInviting}
              onClick={unauthorized.handleReInvite}
              className="w-full"
            />
          )}
          <FormSubmitButton
            color="primary"
            value={buttonName}
            className="w-full"
            isReadOnly={isUpdating}
          />
        </div>
      </Form>
    </>
  );
};
