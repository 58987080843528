export const parseCSV = (csvString: string): Record<string, string>[] => {
  const lines = csvString.split("\n").filter((line) => line.trim() !== ""); // 改行で分割し、空行を除去
  if (!lines.length) return []; // 空の場合は空配列を返却

  const parseLine = (line: string): string[] => {
    const result: string[] = [];
    let current = "";
    let insideQuotes = false;

    for (let i = 0; i < line.length; i++) {
      const char = line[i];

      // eslint-disable-next-line quotes
      if (char === '"') {
        // eslint-disable-next-line quotes
        if (insideQuotes && line[i + 1] === '"') {
          // 連続する"はエスケープ文字として処理
          // eslint-disable-next-line quotes
          current += '"';
          i++; // 次の文字をスキップ
        } else {
          // 引用符の開始または終了を切り替え
          insideQuotes = !insideQuotes;
        }
      } else if (char === "," && !insideQuotes) {
        // カンマ区切りを処理
        result.push(current.trim());
        current = "";
      } else {
        // その他の文字を追加
        current += char;
      }
    }

    // 最後の要素を追加
    result.push(current.trim());
    return result;
  };

  const headers = parseLine(lines[0]!); // 最初の行をヘッダーとして取得
  const rows = lines.slice(1); // ヘッダー以外をデータ行として取得

  return rows.map((row) => {
    const values = parseLine(row); // 各行を解析
    return headers.reduce(
      (acc, header, index) => {
        acc[header.trim()] = values[index]?.trim() ?? ""; // ヘッダーをキーに値をマッピング
        return acc;
      },
      {} as Record<string, string>,
    );
  });
};
