import { useMutation, MutationConfigType, UseMutationResult } from "~/libs";

import { ApiClient } from "~/utils";

import { ApisAdminOperatorsCompaniesMutateResponseType } from "~/domains";

type RequestDataType = {
  companyId: string;
  body: {
    copyableCompanyId: string;
  };
};

const request = ({ companyId, body }: RequestDataType) => {
  return new ApiClient()
    .post<ApisAdminOperatorsCompaniesMutateResponseType>(
      `/api/admin_operators/companies/${companyId}/copies`,
      {
        company: body,
      },
    )
    .then((res) => {
      return res.data;
    });
};

type PropsType = {
  options?: MutationConfigType<typeof request>;
};

export const useApisAdminOperatorsCompaniesCopiesCreate = ({
  options = {},
}: PropsType = {}): UseMutationResult<ApisAdminOperatorsCompaniesMutateResponseType> => {
  return useMutation(request, { ...options });
};
