export const AiChatSelectableExpertRoles = [
  {
    id: "1",
    name: "優れたビジネスコーチ",
  },
  {
    id: "2",
    name: "優れたビジネスコンサルタント",
  },
  {
    id: "3",
    name: "優れた心理カウンセラー",
  },
  {
    id: "4",
    name: "優れた経営者",
  },
  {
    id: "5",
    name: "優れたシステムエンジニア",
  },
  {
    id: "6",
    name: "人事の専門家",
  },
  {
    id: "7",
    name: "営業の専門家",
  },
  {
    id: "8",
    name: "マーケティングの専門家",
  },
  {
    id: "9",
    name: "サービス業の専門家",
  },
  {
    id: "10",
    name: "総務・経理の専門家",
  },
];

export const AiChatSelectablePromptTemplates = [
  {
    id: "1",
    name: "業界調査",
    template: `# 指示
以下の箇条書きから、●●業界の「調査」をお願いします。
一歩ずつ考え、最高の出力をお願いします。
・業界の過去5年間の成長性を教えてください
・業界の中での新興企業を教えてください
・業界の中での大手企業を教えてください
・業界の市場規模を教えてください
・業界の法的な規制や制約条件を教えてください
・業界でトレンドとなっている言葉を教えてください
・業界で起きている変化について教えてください
・業界の課題を教えてください

# 制約条件
・簡潔に教えてください
・論理的に教えてください
・海外ではなく、国内の状況を教えてください`,
  },
  {
    id: "2",
    name: "メール文書作成",
    template: `# 指示
メールでお客様からの信頼を獲得したいと考えています。
以下の制約条件とメール内容をもとに、メール文を出力してください。
一歩ずつ考え、最高の出力をお願いします。

# 制約条件
・相手を尊重し、丁寧で誠実さが伝わる文体にする
・信頼獲得を目指す
・適度な親密さを出す
・冗長でない、簡潔で読みやすい文章にする
・最終目的は自社サービスを相手に導入してもらうこととする
・押し売りはしない
・日本のビジネス慣習に従い、正しい敬語を使う
・「！」や「？」は使わない
・相手に興味を持ってもらう工夫をする
・相手にお願いをする際は一方的にならないよう、相手の都合を尊重した聞き方にする

# メール内容
(ここにメール内容や目的などを記載。以下は例)
・資料請求したお客様に対するメール
・まず資料請求への感謝を伝える
・資料請求のきっかけとお客様の課題を自然な形で聞き出し、返信いただけるよう促す
・自然な形で、ヒアリングと製品デモのお打ち合わせの打診をする
・お打ち合わせでは他社事例など、お客様にお役立ていただける情報を提供することを伝える`,
  },
  {
    id: "3",
    name: "議事録アシスト",
    template: `# 条件
・一部、誤字脱字が含まれています。この点を考慮して、文脈を理解し、内容を整理してください
・会議での主要な「報告内容」を各人ごとにまとめてください。
・報告の内容に関してフィードバックコメントと思われるものは分けてまとめてください。
・見出しや箇条書きを活用し、情報が検索しやすく、読みやすい構造で記述してください。
・文書は簡潔かつ明瞭に記述してください。
・専門用語や略語を使用する場合は、初回の使用時に定義を明記してください。
・ケバ取りしてください。
・文脈として意味が不明な箇所は、文脈的に相応しい合理的に推測される内容に修正、または削除してください。

# 出力形式
【会議のタイトル】
【日時】
【参加者】
【会議の目的（なぜこの会議が行われるか）】
【会議のゴール設定（会議終了時にはどういう状態が理想か）】
【会議のアジェンダ】
【各議題に関する詳細な議論内容】
【決定事項】
【次回会議の日時と場所（決定している場合）】

# 議事録の題材とメモ
（題材とメモをここに貼り付け）
`,
  },
  {
    id: "4",
    name: "話を聞いてもらう",
    template: `# 条件
・洞察やヒントを含みながら、優しい言葉を使う
・会話の内容は一つに絞り込み、解決するまで話題はそのままにする
・常にMECEの論理を意識し、会話が継続するように導く
・会話を続けるための質問は、常に相手を高いレベルに引き上げるものにする
・指摘、解答を繰り返す
・（ここに追加条件）

# 相談者のプロフィール
・仕事：
・目標：
・（ここに追加条件）

# 相談内容
・(ここに相談内容を記載)
`,
  },
];
