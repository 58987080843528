import React, { FC, FormEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { toast } from "react-toastify";

import {
  useApisAdminOperatorsCompaniesCopiesCreate,
  useApisAdminOperatorsCompaniesCopiesIndex,
  useDropdown,
} from "~/hooks";

import { Form, FormSubmitButton } from "~/components/atoms";
import { AdminOperatorLayout } from "~/components/layouts";
import { LabelWithDropDownField } from "~/components/molecules";

import { MyRoutes } from "~/config/Paths";

export const AdminOperatorsCompaniesCopiesIndex: FC = () => {
  const navigate = useNavigate();

  const { companyId = "" } = useParams<{ companyId: string }>();

  const { data } = useApisAdminOperatorsCompaniesCopiesIndex({
    companyId,
  });
  const { mutate, isLoading } = useApisAdminOperatorsCompaniesCopiesCreate();

  const company = data?.company;
  const copyableCompanies = data?.copyableCompanies;

  const [
    {
      dropdownValue: selectedCompany,
      onChange: onChangeCompany,
      dropdownSelectableValue: selectableCompanies,
    },
  ] = useDropdown({
    selectableValue: copyableCompanies,
  });

  const breadCrumbItems = [
    {
      label: "運営者画面",
      href: MyRoutes.AdminOperators.root(),
    },
    {
      label: company?.name || "",
      href: MyRoutes.AdminOperators.companiesShow({ id: companyId }),
    },
    {
      label: "コピー",
      href: MyRoutes.AdminOperators.companiesCopiesIndex({ companyId }),
    },
  ];

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isConfirm = confirm(
      "この操作は取り消せません。本当にコピーしますか？",
    );
    if (!isConfirm) return;

    mutate(
      {
        companyId,
        body: {
          copyableCompanyId: selectedCompany?.value || "",
        },
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          navigate(MyRoutes.AdminOperators.companiesShow({ id: companyId }));
        },
        onError: (error) => {
          toast.error(error.message);
        },
      },
    );
  };

  return (
    <AdminOperatorLayout breadCrumbItems={breadCrumbItems}>
      <h1 className="text-xl">
        <span className="font-bold">{company?.name || ""}</span>
        へデータのコピーを行います
      </h1>
      <Form onSubmit={handleSubmit} className="space-y-6 mt-12">
        <LabelWithDropDownField
          labelText="コピー元の会社"
          required
          name="copyCompanyId"
          value={selectedCompany}
          onChange={onChangeCompany}
          options={selectableCompanies}
        />
        <div className="text-right">
          <FormSubmitButton
            value="コピーを行う"
            color="primary"
            isReadOnly={isLoading}
          />
        </div>
      </Form>
    </AdminOperatorLayout>
  );
};
